import axios from "axios"
import {dateMixin} from '../mixins/date.js'
export let closingdateMixin = {
    name: "closingdateMixin",
    mixins:[dateMixin],
    data(){
        return{
            events:[],
            specialHours:[]
        }
    },
    methods:{
        getClosingSchedules(){
            axios.get('closing/schedules')
            .then(resGetClosingSchedules => {
                let schedules = resGetClosingSchedules.data
                for(let schedule of schedules){
                    this.events.push({
                        start:this.formatDate(schedule.datestart),
                        end:this.formatDate(schedule.dateend),
                        title:"fermeture",
                        type:"close",
                        class:"closed",
                        ulid:schedule.ulid,
                        resizable: false,
                        draggable: false
                    })
                }
            })
        },
        getClosingDates(){
            axios.get(`closing/dates`)
            .then(res=>{
                let events = []
                let closingdates = res.data.result
                let repeating_date=[]
                for (let date of closingdates) {
                    // si la fermeture n'est pas une répétition
                    if(!date.repeating){
                        events.push({
                         start:this.formatDate(date.start_closing_at),
                         end:this.formatDate(date.end_closing_at),
                         title:"fermeture",
                         class:"closed",
                         ulid:date.ulid
                        })
                    }

                    // si la fermeture est une répétition
                    if(date.repeating){
                        // intégration des ulids et description dans chaque closing date  -- SPECIAL HOURS
                        let closing_date_repeating_with_ulid= date.closing_date_repeating.map(x => ({...x, ulid:date.ulid, description:date.description}))
                        repeating_date.push(...closing_date_repeating_with_ulid)
                    }
                }
                this.events = events
            })
            .catch(errGetOrder=>console.log(errGetOrder))
        },
        createRepeatingDate(repeating_date){
            let object= {
                0:[],
                1:[],
                2:[],
                3:[],
                4:[],
                5:[],
                6:[]
            }
            
            // contenu du jour du special hours
            let content = []
            for(let date of repeating_date){
                let params={
                // ex: 12 * 60
                from:this.convertHHMMToNumber(date.r_start_hours),
                to:this.convertHHMMToNumber(date.r_end_hours),
                class:"closed",
                label:date.description?date.description:"fermé"
                }

                // si weekly
                if(object[date.r_day] && date.r_day){
                content.push(params)
                }else if (!object[date.r_day] && date.r_day){
                content = [params]
                // si daily
                }else if(!date.r_day){
                    for(let day in object){
                        object[day].push(params) //étaler les fermetures sur tous les jours
                    }
                }
                object[date.r_day] = content
            }
            this.specialHours = object
        },
        
    },
    mounted(){
        
    }
}