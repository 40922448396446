<template>
    <section class="section-add-service mb-3">
        <b-button ref="openModalButton" v-b-modal="modalId" class="btn btn-block btn-primary mobile">{{text}}</b-button>
        <b-modal ref="myModal" :id="modalId"  size="lg" hide-footer hide-header centered>
            <!-- loader -->
            <div v-if="!searchServiceResult" class="jumping-dots-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div v-else> <!--le loader se ferme-->
                <div class="row">
                    <!-- DATE et informations du client -->
                    <div class="col-md-4">
                        <!-- Date -->
                        <b-card class="p-2 mt-1" no-body>
                            <h4 class="mb-3">Date de réservation</h4>
                            <div class="d-flex ">
                                <p><i class="fa fa-calendar mx-2"></i></p> 
                                <!-- Date -->
                                <vc-date-picker v-model="date" :minute-increment="5" mode="date" :model-config="modelConfig" is24hr >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input
                                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        />
                                    </template>
                                </vc-date-picker>
                                <!-- Heure -->
                                <p><i class="mdi mdi-clock mx-2"></i></p>
                                <vc-date-picker v-model="date" :minute-increment="5" mode="time" :model-config="modelConfig" is24hr >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input
                                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 w-100"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        />
                                    </template>
                                </vc-date-picker>
                            </div>
                        </b-card>
                        <!-- informations clients -->
                        <b-card class="p-2 mt-1" no-body>
                            <h4 class="mb-3">Informations du client</h4>
                            <!-- Nom -->
                            <b-form-group label="Nom du client" label-for="name">
                                <b-form-input v-model="client.name"
                                    type="text" id="firstname"
                                    placeholder="Nom et prénom" size="lg">
                                </b-form-input>
                            </b-form-group>
                            <!-- phone -->
                            <b-form-group label="Téléphone" label-for="phone">
                                <b-form-input v-model="client.phone"
                                    type="text" id="phone"
                                    placeholder="Numéro téléphone" size="lg">
                                </b-form-input>
                            </b-form-group>
                            <!-- informations supplémentaires -->
                            <b-form-group label="Informations supplémentaires" label-for="info">
                                <b-form-textarea v-model="client.info"
                                    type="text" id="phone"
                                    placeholder="Informations supplémentaires" size="lg" :rows="3">
                                </b-form-textarea>
                            </b-form-group>
                        </b-card>
                        <b-card class="p-2 mt-1" no-body>
                            <!-- TAGS: liste des services sélectionnées -->
                            <div>
                                <label for="tags-basic">Prestations ajoutées</label>
                                <b-form-tags input-id="tags-basic" v-model="tagServices" size="lg" tag-variant="secondary"
                                placeholder=" " no-outer-focus disable-add-button no-add-on-enter 
                                ></b-form-tags>
                            </div>
                        </b-card>
                    </div>
                    <div class="col-md-8">
                        <b-card class="p-2" no-body>
                            <h4 class="mb-3"> Prestations </h4>
                            <!-- Sélection des services -->
                            <b-tabs class="computer" pills card vertical>
                                 <!-- Barre de recherche -->
                                <div class="row m-1">
                                    <div v-if="allService" class="offset-md-8 col-md-4 col-sm-12 p-0">
                                        <div class="input-group">
                                            <input v-model="inputSearch"  @keyup="searchService()" class="form-control form-control-lg" id="navbar-search-input" placeholder="Rechercher une prestation" aria-label="search" aria-describedby="search">
                                            <div class="input-group-append">
                                                <button type="submit" class="input-group-text form-control form-control-lg"><i class="fa fa-search icon-sm"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- tabs tous  -->
                                <b-tab title="Tous" @click="allService =true">
                                    <b-list-group>
                                        <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in searchServiceResult" :key="index">
                                            <div class="d-flex justify-content-between">
                                                <div class="" style="width:8rem">{{item.name}}</div>
                                                <div class="">{{calculTimes(item.duration)}}</div>
                                                <div class="" style="text-align:right">{{item.price}} €</div>
                                                <div class="">
                                                    <button class="btn btn-social-icon btn-secondary" style="width:35px; height:35px" @click="addTagService(item)"><i class="mdi mdi-plus"></i></button>
                                                </div>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-tab>
                                <!-- tabs des catégories sélectionnées -->
                                <b-tab  @click="allService =false" :title="item.category"  v-for="(item, index) in categorie" :key="index" >
                                    <b-list-group>
                                        <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in item.services" :key="index">
                                            <div class="row">
                                                <div class="col-md-6">{{item.name}}</div>
                                                <div class="col-md-2">{{calculTimes(item.duration)}}</div>
                                                <div class="col-md-2" style="text-align:right">{{item.price}} €</div>
                                                <div class="col-md-2">
                                                    <button class="btn btn-social-icon btn-secondary" @click="addTagService(item)"><i class="mdi mdi-plus"></i></button>
                                                </div>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-tab>
                            </b-tabs> <!--Fin sélection des services-->
                        </b-card>
                    </div>
                </div>

                <b-card class="p-2 mobile" no-body>
                    <!-- affichage mobile -->
                    <b-tabs class="mobile tab-solid tab-solid-danger">
                        <b-tab title="Tous" @click="allService =true">
                            <b-list-group>
                                <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in searchServiceResult" :key="index">
                                    <div class="row">
                                        <div class="col-md-12">{{item.name}}</div>
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <span>
                                                <div>{{calculTimes(item.duration)}}</div>
                                                <div>{{item.price}} €</div>
                                            </span>
                                            <button class="btn btn-secondary btn-rounded btn-icon" @click="addTagService(item)"><i class="mdi mdi-plus"></i></button>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </b-tab>
                        <b-tab  @click="allService =false" :title="item.category"  v-for="(item, index) in categorie" :key="index" >
                            <b-list-group>
                                <b-list-group-item href="#" @click="selectedService = item"  v-for="(item, index) in item.services" :key="index">
                                    <div class="row">
                                        <div class="col-md-12">{{item.name}}</div>
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <span>
                                                <div>{{calculTimes(item.duration)}}</div>
                                                <div>{{item.price}} €</div>
                                            </span>
                                            <button class="btn btn-secondary btn-rounded btn-icon" @click="addTagService(item)"><i class="mdi mdi-plus"></i></button>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            <!-- validation écran large -->
            <hr class="computer">
            <div class="d-flex justify-content-end computer ">
                <button class="btn btn-link mx-4" @click="$bvModal.hide(modalId)">Annuler</button>
                <button class="btn btn-secondary mx-4" v-if="!edit" @click="addArrayServices()">VALIDER</button>
                <button class="btn btn-secondary mx-4" v-else @click="addArrayServices(true, ulid)">MODIFIER</button>
            </div>
            <!-- validation mobile -->
            <div class="d-flex justify-content-around validation_section mobile ">
                <button class="btn btn-link w-100" @click="$bvModal.hide(modalId)">Annuler</button>
                <button class="btn btn-secondary w-100" v-if="!edit" @click="addArrayServices()">VALIDER</button>
                <button class="btn btn-secondary w-100" v-else @click="addArrayServices(true, ulid)">MODIFIER</button>
            </div>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'

export default {
    name: 'add-service',
    data () {
        return {
            ulid:'',
            selectedService: [],
            inputSearch:'',
            searchServiceResult:null,
            allService:true,
            categorie:null,
            services:null,
            tagServices:[],
            client:{
                name:'',
                phone:'',
                info:'',
            },
            date:'',
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DDTHH:mm:ss', // Uses 'iso' if missing
            },
            heure:"",
            edit:false
        }
    },
    props:{
        text:{
            type:String,
            default:"Ajouter un rendez-vous"
        },
        modalId:{
            type:String,
            default:"modal"
        }
    },
    methods:{
        openModal() {
            this.$refs.myModal.show();
        },
        openModalWithParameter(date,ulid) {
            if(ulid){
                this.getReservation(ulid)
                this.edit = true
            }
            this.$refs['myModal'].show()
            this.date = date
        },
        getCategories(){
            let url = "categories"
            axios.get(url)
                .then(
                    resgetcategories => {
                        this.categorie = resgetcategories.data.result
                        this.categorie = this.categorie.filter(element => element.ulid !="01FY46MX2EW85PP30KQ6S9BXG3")
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        getService(){
            let url ="services"
            axios.get(url)
                .then(
                    resgetService => {
                        this.services = resgetService.data.data
                        console.log('services', this.services);
                        this.services = this.services.filter(service => service.isactive)
                        console.log(this.services);
                        this.searchServiceResult =this.services
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        select(item){
            this.selectedService=item
        },
        // ajouter le service dans la liste à ajouter
        addTagService(service){
            console.log('add tag service', service);
            this.tagServices.push(service.name)
        },
        // envoyer la liste dans le tableau
        addArrayServices(put, ulid){
            // vérification si tous les champs sont complétés
            if( !(this.date) || !(this.client.name) ) {
                alert ("Veuillez mettre la date de rendez vous et le nom du client")
                return
            }

            if(this.tagServices.length !== 0){
                this.$snotify.success("Prestations ajoutées au panier", {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    });
                let services = this.services.filter(service => this.tagServices.includes(service.name.trim()))
                this.$emit('updateService', {
                    services: services,
                    client:this.client,
                    date:this.date
                },
                put,
                ulid)
                this.$bvModal.hide(this.modalId)
                this.tagServices = []
            }else{
                alert('Veuillez sélectionner au moins une prestation')
            }
        },
        fastAddService(item){
            console.log('fast add service',item);
            // this.selectedService = [item]
            // this.addService()
        },
        addService(){   
            if(this.selectedService){
                this.$snotify.success("Prestation ajoutée au panier", {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    });
                    this.$emit('updateService',this.selectedService)
                    this.selectedService=null
                    this.$bvModal.hide(this.modalId)
            }else{
                alert('Veuillez sélectionner une prestation')
            }
        },
        searchService()
        {
            this.searchServiceResult=null
            this.currentPage = 1
            const regex = new RegExp(this.inputSearch.toLowerCase());
            let newservice = []
            this.services.forEach(element => {
                if(regex.test(element.name.toLowerCase())){
                    newservice.push(element)
                }
            })
            this.searchServiceResult = newservice
        },
        convertToTime(duration){
            var sec = 3600 * duration
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 8);
            return timeString
        },
        calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            let stringHeure = ''
            if (heure) {
                stringHeure = heure+ ' h'
            }
            if (min) {
                stringHeure += min + 'mn'
            }
            return stringHeure
        },
        getReservation(ulid){
            axios.get(`reservations/${ulid}`)
            .then(resGetReservation => {
                let reservation = resGetReservation.data.result
                let services= []
                for (let appointment of reservation.appointments){
                    services.push(appointment.service.name)
                }
                this.ulid = reservation.ulid
                this.client.name = reservation.on_behalf_of.name
                this.client.phone = reservation.on_behalf_of.phone
                this.client.info = reservation.descriptions
                this.tagServices = services
                console.log("reservation dans le add service", reservation);
            })
            .catch(errGetReservation => errGetReservation)
        },
        // réinitialiser toutes les données 
        resetData() {
            this.edit = false
            this.tagServices=[],
            this.client={
                name:'',
                phone:'',
                info:'',
            },
            this.date = ''
        },
    },
    mounted(){
        this.getCategories()
        this.getService()

        // écouter la fermeture du modal et réinitialiser les données
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            bvEvent
            modalId
            this.resetData()
        })
    }
}
</script>
<style scoped>
::v-deep .tab-pane.active {
    max-height: 50vh;
    overflow: auto;
}
::v-deep .tab-solid-danger .nav-link.active{
    background: #94c133;
    color: #fff;
}
::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background-color: #260d4f !important;
    color: #fff;
}


@media screen and (min-width: 992px) {
    /* ::v-deep .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    text-decoration: none;
    border: 2px solid #94c133;
    margin: 1px;
    } */
}
@media screen and (max-width: 992px) {
    .section-add-service{
        width: 100%
    }
}

/* form tag */
::v-deep .b-form-tag{
    background: rgb(50, 50, 50);
    font-size: 16px;
}
/* cacher le bouton add */
::v-deep .b-form-tags-button{
    display: none
}
::v-deep #tags-basic{
    cursor: default;
}
:deep .tab-content {
    padding: 0rem !important;
}


</style>