<template>
    <section>
        <i class="mdi mdi-pen pointer" v-b-modal="modalKey"></i>

        <b-modal :id="modalKey" :title="name" hide-footer>
            <!-- duration -->
            <b-form-group id="duration" label="Durée (hh:mm)" label-for="example-input-1">
                <b-form-timepicker 
                    v-model="edited_duration"
                    id="example-input-1"
                    name="example-input-1"
                    minutes-step="5"
                    no-close-button
                    aria-describedby="input-1-live-feedback"
                    locale="fr">
                    </b-form-timepicker>
                <b-form-invalid-feedback id="input-1-live-feedback">
                    Ce champ est obligatoire.
                </b-form-invalid-feedback>
            </b-form-group>

            <hr>
            <div class="d-flex justify-content-end">
                <button class="btn btn-link" @click="$bvModal.hide(modalKey)">Annuler</button>
                <button class="btn btn-success mx-4" @click="editDuration()">Valider</button>
            </div>
        </b-modal>
    </section>
</template>
<script>
export default {
    name:"edit-duration",
    data(){
        return{
            edited_duration:this.duration
        }
    },
    props:{
        index:{
            default:0,
            type:Number
        },
        modalKey:{
            default:"modal1",
            type:String
        },
        name:{
            default:"service",
            type:String
        },
        duration:{
            default:"00:00",
            type:String
        }
    },
    methods:{
        editDuration(){
            this.$emit("editDuration",this.edited_duration, this.index)
            this.$bvModal.hide(this.modalKey)
            this.$snotify.success("Durée modifiée avec succès", {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
            });
        }
    }
}
</script>