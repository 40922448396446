<template>
    <section>
        <vue-snotify></vue-snotify>
        <div class="row d-flex justify-content-center">
            <div v-if="loader">
                <div class="jumping-dots-loader "><span></span><span></span><span></span></div>
                <span>En cours de chargement</span> 
            </div>
            <div v-else class="col-md-12 time">
                <div v-if="agendaLoader" class="loading d-flex justify-content-center align-items-center">
                    <div class="circle-loader"></div>
                </div>
                <VueCal
                :key="vueCalKey"
                :style="eventsStyle+' '+ 'vuecal__event'"
                :time-from="7 * 60"
                :time-to="21 * 60"
                :time-step="15"
                :events="events"
                locale="fr"
                :hideWeekdays="[1,7]"
                :disableViews="disableViews"
                :clickToNavigate="false"
                show-time-in-cells
                :snapToTime="15"
                :drag-to-create-threshold="15"
                :selected-date="dateString()"
                :editable-events="mobileDevice ? false : true"
                :on-event-click="eventInfo"
                @cell-click="getTime('reservation-create', $event)"
                @event-drag-create="createClosing"
                @event-duration-change="editReservation($event)"
                @event-drop="editReservation($event)"
                @event-delete="deleteReservation('delete-reservation', $event)"
                >
                <!-- @cell-dblclick="getTime('reservation-create', $event)" -->
                <!-- editable-events -->
                    <template v-slot:event="{ event }">
                        <!-- <div class="event-content" :style="{ background: event.background, color: event.color }" :title="event.content"> -->
                        <div class="event-content" :style="'background:'+event.backgroundColor +'; color:white'" :title="event.content">
                            <div class="vuecal__event-title vuecal_title" v-html="event.title"></div>
                            <hr /><em class="vuecal__event-time"><span> {{ event.start.formatTime() }}</span> <strong>à</strong> <span>{{ event.end.formatTime() }}</span></em>
                            <p class="vuecal__event-content">{{event.content}}</p>
                        </div>
                    </template>
                </VueCal>
            </div>
        </div>

        <!-- détails de la prestation -->
        <b-modal id="modal"  hide-footer :title="selected2.title" static>
            <div class="card">
                <div class="card-body" >
                    <!-- si fermeture -->
                    <span v-if="selected2.type">
                        <p class="clearfix"><span class="float-left"> Description </span><span class="float-right text-muted"> {{selected2.title}} </span></p>
                        <p class="clearfix"><span class="float-left"> Jour </span>
                            <span class="float-right text-muted">
                             Tous les jours
                            </span></p>
                        <p class="clearfix"><span class="float-left"> Début </span>
                            <input class="float-right form-ailem" type="time" v-model="selected2.r_start_hours">
                        </p>
                        <p class="clearfix"><span class="float-left"> Fin </span>
                            <input class="float-right form-ailem" type="time" v-model="selected2.r_end_hours">
                        </p>
                    </span>

                    <!-- si réservation -->
                    <span v-else>
                        <div class="d-flex">
                            <div>
                                <input type="date" v-model="selected2.date">
                            </div>
                            <div>
                                <input type="time" v-model="selected2.r_start_hours">
                            </div>
                        </div>
                    </span>
                </div>
                <hr>
                <div class="d-flex my-3">
                    <!-- suppression fermeture date (si fermeture) -->
                    <button v-if="selected2.title=='fermeture'" class="btn btn-danger btn-social-icon mx-4 mr-auto" @click="deleteClosingDate(selected2.ulid)" title="Cette action effacera cette fermeture de date"><i class="fa fa-trash"></i></button>
                    <!-- fermeture du modal -->
                    <button class="btn btn-link" @click="$bvModal.hide('modal')">Annuler</button>
                    <!-- validation/modification du closing date (si fermeture) -->
                    <button class="btn btn-secondary mx-2" v-if="selected2.title=='fermeture'" @click="editClosingDate(selected2.ulid)">Valider</button>
                    <!-- validation modification si réservation -->
                    <!-- <button v-if="selected2.title!=='fermeture'" class="btn btn-primary mx-4" @click="editReservation(selected2)">Enregistrer</button> -->
                    <!-- détails de la réservation (si réservation) --> 
                    <button v-if="selected2.title!=='fermeture'" class="btn btn-primary mx-4" @click="onEventClick(selected2)"><i class="fa fa-eye"></i></button>
                </div>
                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>


import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import axios from 'axios';
import { dateMixin } from '@/pages/dashboard/mixins/date.js'
import { closingdateMixin } from '../../pages/dashboard/mixins/closing_date_calendar';
export default {
    name:'full-time-picker-meb',
    mixins:[dateMixin, closingdateMixin],
    components:{
        VueCal
    },
    data(){
        return {
            events2: [
                {
                    start: '2023-06-22 10:00',
                    end: '2023-06-22 12:00',
                    title: 'Boring event',
                    content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
                    class: 'blue-event',
                    deletable: false,
                    resizable: true,
                    draggable: true
                },
                ],
            purchases:[],
            service:[],
            closingDates:[],
            // events: [],
            loading: true,
            disabled:"years",
            selected:{},
            loader:true,
            agendaLoader:false,
            selected2:{
                description:"",
                start: new Date(),
                end: new Date(),
                ulid:'',
                ulidPurchases:'',
                r_start_hours:"",
                r_end_hours:""
            },
            selectedClosingDate:{},
            isload:false,
            editableEvent:[],
            vueCalKey:111,
            modeEdit:false,
            eventCreatedByDrag:false
        }
    },
    computed:{
        eventsStyle(){
            return "background-color: red"
        },
        disableViews(){
            if(this.$device.windows){
                return ['years','year']
            }else{
                return ['years','year','month','week']
            }
        },
        isedit(){
            return this.editableEvent.length >0
        },
        typeDateToDDMMYYYY2(){
            let date = new Date()
            return date.getDay() + "-" + date.getMonth() + "-" + date.getYear()
        },
        mobileDevice(){
            return !this.$device.windows
        }
    },
    methods:{
        // suppression réservation -- action: maintenir la réservation
        deleteReservation(name, event){
            name
            // si l'évènement cliqué est une 'fermeture'
            if (event.class ==="closed"){
                axios.delete(`closing/dates/${event.ulid}`)
                .then(resDeleteClosingDate => {
                    console.log({resDeleteClosingDate});
                })
                .catch(errDeleteClosingDate => console.error(errDeleteClosingDate))
            }
            // si l'évènement cliqué est une 'réservation'
            else{ 
                axios.delete(`reservations/${event.ulid}`)
                .then(resDeleteReservation => {
                    console.log({resDeleteReservation});
                })
                .catch(errDeleteReservation => console.error(errDeleteReservation))
            }
        },
        // création d'une réservation
        getTime(title, data){
            console.log({title});
            console.log({data});
            if(title==="reservation-create" && data && !this.eventCreatedByDrag){
            // conversion de la date à 15mn près
            let nearest15mnDate = this.roundDownToNearest15Minutes(data)
            let date = this.formatDate4(nearest15mnDate)
            this.$emit('selected_time',date)
            }
        },
        // création d'une fermeture
        createClosing(event){
            console.log('création de create closing', event);
            this.eventCreatedByDrag = true
            this.$emit('closing_time',{
                start: this.formatDate4(event.start),
                end: this.formatDate4(event.end),
            })
            console.log({event});
        },
        eventDrop(event) {
            console.log({event});
        },
        async editReservation(event){
            let event_class = event.event.class //fermeture ou réservation
            let ulid = await event.event.ulid //ulid de l'évènement
            let end = await event.event.end // date de fin
            let start = await event.event.start // date de début
            // si on modifie une réservation
            if(event_class !== 'closed') {
                this.editAppointments(ulid, end, start)
            } else { //si on modifier un fermeture
                console.log("edit_closing_date");
                this.editClosingDate(ulid, end, start)
            }
        },
        async editAppointments(ulid, end, start){
            this.agendaLoader = true
            // formatage de l'heure de début et fin pour convenir au body de put/appointment
            let start_hour = this.formatDate4(this.roundedToNearestFive(start))
            let end_hour = this.formatDate4(this.roundedToNearestFive(end))
            // récupérer les informations de la réservation sélectionnées
            axios.get(`reservations/${ulid}`)
            .then(async resReservation => {
                let reservation = resReservation.data.result
                // parcours et modification des appointments
                let putAllAppointments = reservation.appointments.map(appointment => this.putAppointment(reservation.ulid,appointment.ulid,appointment.service.ulid, start_hour, end_hour))
                await Promise.all(putAllAppointments)//attendre le put/appointment avant d'actualiser les données du tableau
                .then(resPromisePutAllAppointments => {
                    resPromisePutAllAppointments
                    // rafraichissement de l'agenda
                    // this.getClosingDates()
                    // this.getClosingSchedules()
                    // this.getService()
                    // this.vueCalKey++
                    this.agendaLoader =false
                })
                
            })
            .catch(errReservation => {
                this.agendaLoader =false
                console.log('erreur dans editAppointments', errReservation);
                alert('Erreur du get reservations');
                return
            })
            // this.loader = true
        },
        putAppointment(reservation_ulid,appointment_ulid,service_ulid, start_hour, end_hour){
            return axios.put(`appointments/${appointment_ulid}`,
            {
                reservations: reservation_ulid,
                start_hour: start_hour,
                end_hour: end_hour,
                service:service_ulid
            }
            )
                .then(resPutFirstAppointment => {
                    resPutFirstAppointment
                })
                .catch()
            },
        // ancienne modification de la réservation
        // editReservation(reservation){
        //     this.$emit('edit-reservation',{
        //         ulid:reservation.ulid,
        //         date:this.selected2.date,
        //         start:this.selected2.r_start_hours
        //     })
        // },
        
        formatDate(dateString){
            let utc = dateString.charAt(21)
            // utc == heure 
            let date = new Date(dateString)
            let originalUTC = date.toISOString().split('T')[1].slice(0,5)
            let getHour = parseInt(originalUTC.split(':')[0])
            let displayedHour = getHour + parseInt(utc)
            date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
            return date
        },
        dateString(mydate){
            var stringdate;
            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        getService() {
            axios.get('services')
            .then(
                resService=> {
                    this.service= resService.data.data
                    this.getPurchases()
            })
            .catch(errService=> console.log(errService))
      },
      getPurchases(){
        //   axios.get('reservations_2lw') //2lastweeks
          axios.get('reservations') //jusqu'à ce que la reservation_date se transforme
                .then(
                    resreservations => {
                        // récupérer toutes les réservations, enlever les réservations annulées
                        let reservations =resreservations.data.data.filter(
                            reservation => reservation.status !== "cancelled"
                        )
                        // let reservations =resreservations.data.result
                        this.createEvent(reservations)
                        // this.loader = false
                    }
                )
                .catch(
                    errpurchases=> {
                        errpurchases
                    }
                )
      },
      async createEvent(data){
          for (let reservation of data) {
            let backgroundColor ="#"+(Math.floor(Math.random()*16777215).toString(16))

            //trier les appointments par date (décroissant)
            let sortedAppointments =await reservation.appointments.sort((appointment1, appointment2) => 
            new Date(appointment1.start_hour) - new Date(appointment2.start_hour))
        
            if(sortedAppointments[0].start_hour === null || sortedAppointments[0].start_hour === undefined || sortedAppointments[reservation.appointments.length - 1].end_hour === null || sortedAppointments[reservation.appointments.length - 1].end_hour === undefined) {
                continue
            }
            // récupérer la 1ère date du 1er appointment et le dernier date du dernier appointment
            let date_reservation = {
                start: this.formatDate(sortedAppointments[0].start_hour),
                end: this.formatDate(sortedAppointments[reservation.appointments.length - 1].end_hour) 
            }
            
            // récupérer les prestations
            let appointments = []
            for (let appointment of reservation.appointments) {
                appointments.push(appointment.service.name)
            }
           
            let appointment_to_text = ""
            if (appointments.length > 1 ) {
                appointment_to_text = appointments.join(", ") //afficher l'appointment en tant que String
            }else if (appointments.length === 1 ) {
                appointment_to_text = appointments[0]
            } else {
                appointment_to_text = "prestation absente"
            }
            // mettre le tout dans l'agenda
            this.events.push({
                start:date_reservation.start,
                end:date_reservation.end,
                title:reservation.on_behalf_of.name,
                content:appointment_to_text, 
                ulid:reservation.ulid,
                // class: bgcolor,
                backgroundColor: backgroundColor,
                deletable: true,
                resizable: true,
                draggable: true
                })
            }
        this.loader = false // solution de secours
      },
      onEventClick(event){
          if(event.title=="FERMETURE - Laurie Chonion"){
            return
            }
            this.ordersDetails(event.ulid)
      },
      eventInfo(event, e){
          // Prevent navigating to narrower view (default vue-cal behavior).
        e.stopPropagation()
        console.log({e});
        if(event.class !== 'closed'){
            this.$emit('reservation_info',{
                date:this.formatDate4(event.start),
                ulid:event.ulid
            })
        }
      },
      onEventCreate(event, deleteEventFunction){
        console.log("on event create", event);
        console.log({deleteEventFunction});
      },
      ordersDetails(ulid){
          this.$router.push({
              name:"ordersDetails",
              params:{
                  ulid:ulid
              }
          })
      },
      getSelectedClosingDate(ulid){
        axios.get(`closing/dates/${ulid}`)
        .then(resGetClosing => {
            this.selectedClosingDate=resGetClosing.data.result
        })
        .catch(errGetSelectedClosingDate=> console.log(errGetSelectedClosingDate))
      },
      editClosingDate(ulid, end, start){
        // formatage de l'heure de début et fin pour convenir au body de put/appointment
        let start_hour = this.formatDate4(this.roundedToNearestFive(start))
        let end_hour = this.formatDate4(this.roundedToNearestFive(end))

        axios.put(`closing/dates/${ulid}`,{
            companies:localStorage.getItem('company_client_ulid'),
            start_closing_at: start_hour,
            end_closing_at: end_hour,
            repeating: false,
        })
        .then(resEditClosingDate => {
            console.log({resEditClosingDate});
        })
        .catch(errDeleteClosingDate => {
            console.log({errDeleteClosingDate});
        })
        },
      deleteClosingDate(ulid){
          this.$swal({
                title: 'Voulez-vous supprimer cette fermeture?',
                showCancelButton: true,
                confirmButtonColor: '#1bcfb4;',
                cancelButtonColor: 'rgb(206, 118, 122)',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler'
            })
            .then((result) => {
                if (result.value) {
                this.isload = true;
                // this.deletePurchase(ulidPurchase)
                axios.delete(`closing/dates/${ulid}`)
                .then(resDelete => {
                    resDelete
                    location.reload()
                })
                .catch(errDelete => console.log({errDelete}))
                }
            })
      },
      deletePurchase(ulidPurchase){
          axios.delete(`purchases/${ulidPurchase}`)
          .then(resDeleteClosingDate => {
            resDeleteClosingDate
              location.reload()
          })
          .catch(errDeleteClosingDate => console.log(errDeleteClosingDate))
      },
      keyCalendar(){
          this.$emit('keyCalendar', this.vueCalKey++)
      }
    },
    mounted(){
        this.getClosingDates()
        // this.getClosingSchedules()
        this.getService()
        this.$device
        console.log('device', this.$device);
    }
}
</script>
<style scoped>
:root{
--randomColor:
}
.randomColor{
    background-color: --randomcolor;
}
.time{
    min-height: 50rem;
}
::v-deep .orange{
    background: #cda525;
    color: #fff;
}
:deep .closed {
  background:
    #fff7f0
    repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.25) 5px,
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 0) 15px
    ) !important;
  color: #B4AFC3 !important;
}
.vuecal_title{
    text-shadow: 4px 3px 9px rgba(0,0,0,0.6);
}
.event-content{
    height: 99%;
    border-radius: 4px;
    padding: 4px;
}

:deep .vuecal__event.bgcolor_1 {
  background-color: #2F455C;
  color:#fff
}
:deep .vuecal__event.bgcolor_2 {
  background-color: #250C4E;
  color:#fff
}
:deep .vuecal__event.bgcolor_3 {
  background-color: #3A8161;
  color:#fff
}
:deep .vuecal__event.bgcolor_4 {
    background-color: #B4AFC3;
    color: #fff;
}
:deep.vuecal__event.bgcolor_5 {
    background-color: #7BB043;
    color: #fff;
}

/* limite de hauteur de l'agenda */
:deep .vuecal__flex.vuecal.vuecal--week-view.vuecal--fr.vuecal--view-with-time {
    /* max-height: 45rem; */
 }

 /* loading du fullcalendar */
 .loading {
    background: rgba(255, 255, 255, 0.114);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 500;
 }

 @media screen and (max-width: 992px) {
    .time {
        padding:0rem 1rem
    }
 }
</style>