<template>
    <section class="cream-background">
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div v-else class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-between justify-content-between">
                                <h1>Réservations</h1>
                                <span class="d-flex justify-content-center align-items-center align-self-center">
                                    <!-- ordinateur -->
                                    <router-link class="computer" style="color:black" :to="{name:'listOfReservations'}">Détails des réservations</router-link>
                                    <!--mobile-->
                                    <router-link class="mobile" style="color:black" :to="{name:'listOfReservations'}"> <button class="btn btn-social-icon btn-outline-primary btn-rounded ml-3"><i class="mdi mdi-calendar"></i></button></router-link>

                                    <p style="margin:0px 1rem" class="pointer"> </p>
                                    <button v-b-modal.help class="btn btn-social-icon btn-outline-primary btn-rounded computer"><i class="mdi mdi-help"></i></button>
                                </span>
                                
                                <b-modal id="help" title="Aide" hide-footer hide-backdrop>
                                    <p>
                                        <b>Ajouter une réservation</b>
                                        <br>- Double cliquer sur l'agenda au niveau de l'horaire voulu
                                        <br><b class="mt-1">Modifier une réservation</b>
                                        <br>- Cliquer sur la réservation, modifier les données.
                                        <br> <b class="mt-1">Ajouter une fermeture</b>
                                        <br>- Cliquer sur un horaire vide, maintenir et glisser vers le bas
                                        <br> <b class="mt-1">Supprimer une réservation ou une fermeture</b>
                                        <br>- Cliquer et maintenir sur la réservation/fermeture sur l'agenda, appuyer ensuite sur "supprimer"
                                    </p>
                                </b-modal>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-end">
                                    <!-- Ecran large -->
                                    <add-service class="computer"
                                    ref="add_service"
                                    :modalId="'addservice1'"
                                    text="Ajouter un rendez-vous"
                                    key="addservice1"
                                    @updateService="addService"
                                    />
                                </div>
                                <!--tableau reservation écran large -->
                                <!-- <div class="table-responsive computer">
                                    <table class="table">
                                        <thead>
                                            <th>Prestation</th>
                                            <th>Durée</th>
                                            <th>Tarif</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody v-if="!services.length">
                                            <tr>
                                                <td class="text-muted">Veuillez choisir une prestation</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(item, itemid) in services" :key="itemid">
                                                <td>{{item.name}}</td>
                                                <td class="d-flex">
                                                    <span>{{calculTimes(item.duration)}}</span>
                                                    <edit-duration :index="itemid" :modalKey="'edit'+itemid" :name="item.name" :duration="calculTimes(item.duration)"
                                                    @editDuration="editDuration"
                                                    />
                                                </td>
                                                <td style="text-align:right">{{item.price}} €</td>
                                                <td >
                                                    <span class="d-flex">
                                                        <button class="btn btn-primary btn-sm mx-3" style="background: #cda525;color: #ffffff;border:none" @click="removeService(item)">
                                                            <i class="fa fa-trash pointer" ></i>
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <th>Total</th>
                                            <th></th>
                                            <th style="text-align:right">{{totalpice}} €</th>
                                            <th>Prix total</th>
                                        </tfoot>
                                    </table>
                                </div> -->

                                <!--tableau reservation mobile -->
                                <div class="reservation-mobile mobile card p-1">
                                <!-- liste des prestations -->
                                    <div class="card-body position-relative my-1 d-flex justify-content-between align-items-center" v-for="(item, itemid2) in services" :key="itemid2">
                                        <div class="wrapper w-100">
                                            <label title="En construction" class="badge badge-primary construct pointer" style="z-index:1"><i class="mdi mdi-close" @click="removeService(item)"></i></label>
                                            <h6 class="ml-1 mb-1">{{item.name}}</h6>
                                            <div class="d-flex justify-content-between w-100">
                                                <small class="text-muted mb-0">
                                                    <i class="mdi mdi-timelapse  mr-1"></i>{{calculTimes(item.duration)}} <br>
                                                    {{item.price}}€
                                                </small>
                                                <!-- modification de la durée -->
                                                <edit-duration :index="itemid2" :modalKey="'edit'+itemid2" :name="item.name" :duration="calculTimes(item.duration)"
                                                @editDuration="editDuration"
                                                />
                                            </div>
                                        </div>
                                        <h3></h3>
                                    </div>
                                    
                                    <!-- mobile -- Ajout prestation-->
                                    <add-service class="mobile"
                                    :modalId="'addservice2'"
                                    text="Ajouter un rendez-vous"
                                    key="addservice2"
                                    @updateService="addService"
                                    />
                                    <!-- ajouter une prestation à la suite -->
                                    <!-- <div class="d-flex justify-content-center">
                                        <add-service :modalId="'mobileaddservice'" key="mobileaddservice" text="Ajouter un RDV" @updateService="addService($event,put)"/>
                                    </div> -->
                                </div>


                                <!-- heure et date de début mobile -->
                                <div class="row mt-3 mobile">
                                        <div class="col-12" v-if="services.length !==0">
                                            <h4>Choisir la date du rendez vous</h4>
                                            <date-picker class="mt-2"
                                            v-model="mobile.date"
                                            :disabled-date="disabledDate"
                                            @change="skey++"
                                            valueType="YYYY-MM-DD"
                                            format="DD-MM-YYYY"
                                            placeholder="Choisir une date"
                                            >
                                        </date-picker>
                                        </div>
                                        <div class="col-12 mt-3" v-if="mobile.date && services.length !== 0">
                                            <h4>Choisir l'heure du rendez vous</h4>
                                            <time-picker-meb class="mt-2"
                                            v-model="mobile.start"
                                            :key="`mobile${mobile.date}${skey}`"
                                            type="input"
                                            :modalId="'modal-mobile-time-picker'"
                                            :duration="totalDuration"
                                            :selectedDateTime="mobile.date.substr(0,10)"
                                            :myDate="myDate"
                                            :open_modal="modaltriger"
                                            :purchases_array="purchases_array"
                                            @saveTime="saveTimeMobile" />
                                        </div>
                                </div>

                                <!-- heure et date de début computer -->
                                <div class="row" v-if="services.length !==0" style="display:none">
                                    <div class="col-md-4 computer">
                                        <div class="d-flex justify-content-center">
                                            <div class="card computer w-100" style="">
                                                <div class="card-header">
                                                    <div data-v-f2b16ec4="" class="wrapper d-flex align-items-center justify-content-center media-info primary"><h2 class="card-title">Horaire</h2></div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="flex-column">
                                                        <div class="form-group">
                                                            <label for="datepicker" class="d-block">Date de réservation</label>
                                                                <date-picker class="mt-2"
                                                                v-model="mobile.date"
                                                                :disabled-date="disabledDate"
                                                                @change="skey++"
                                                                valueType="YYYY-MM-DD"
                                                                format="DD-MM-YYYY"
                                                                placeholder="Choisir une date"
                                                                >
                                                            </date-picker>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="datepicker" class="d-block">Heure de début</label>
                                                                <time-picker-meb class="mt-2"
                                                                    v-model="mobile.start"
                                                                    :key="`${mobile.date}${skey}`"
                                                                    type="input"
                                                                    :modalId="'modal-computer-time-picker'"
                                                                    :duration="totalDuration"
                                                                    :selectedDateTime="mobile.date.substr(0,10)"
                                                                    :myDate="myDate"
                                                                    :purchases_array="purchases_array"
                                                                    @saveTime="saveTimeMobile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- résumé et validation -->
                                    <div class="col-md-8 col-sm-12">
                                        <!-- résumé de la réservation -->
                                        <div class="border mt-sm-5 h-100 mt-md-0 mt-sm-5 p-5 d-flex flex-column justify-content-between" id="resume">
                                            <span class="mobile">
                                                <h4>Prestations choisies</h4>
                                                <h5 class="text-muted" v-if="services.length == 0">Prestation vide</h5>
                                                <ul v-else>
                                                    <li v-for="(item) in services" :key="item.ulid">{{item.name}}</li>
                                                </ul>
                                            </span>
                                            <span>
                                                <h4>Date du rendez-vous : </h4>
                                                <h5 class="text-muted" v-if="!mobile.date || !mobile.start">
                                                    Pour fixer la date du rendez-vous:
                                                    <ul>
                                                        <li>Compléter les formulaires d'horaire OU</li>
                                                        <li class="computer">double-cliquer sur les colonnes de l'agenda</li>
                                                    </ul>
                                                </h5>
                                                <h5 class="text-muted" v-else> {{getDate(selected_date)}}</h5>
                                            </span>

                                            <!-- Validation de la réservation -->
                                            <div v-if="mobile.date && mobile.start" class="d-flex justify-content-end align-items-end mt-3">
                                                <button class="btn btn-success btn-dynamic-block" v-if="this.$route.params.ulid" v-b-modal.information>Valider la modification</button>
                                                <button class="btn btn-success btn-dynamic-block" v-else v-b-modal.information>Valider la réservation</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <!--Modal Détails du client -->
                                <b-modal size="md" id="information" hide-footer>
                                    <b-form-group label="Nom du client" label-for="name">
                                        <b-form-input v-model="name"
                                            type="text" id="firstname"
                                            placeholder="Noms et prénoms" size="lg">
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Téléphone" label-for="phone">
                                        <b-form-input v-model="phone"
                                            type="text" id="phone"
                                            placeholder="Numéro téléphone" size="lg">
                                        </b-form-input>
                                    </b-form-group>
                                    
                                    <hr>
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-link mx-2">Annuler</button>
                                        <!-- <button class="btn btn-success" @click="postMyCart()">Valider</button> -->
                                        <button class="btn btn-success" @click="createCartMobile()">Valider</button>
                                    </div>
                                </b-modal>
                            </div>
                        </div>

                        <!-- FULL CALENDAR / AGENDA -->
                        <span class="">
                            <div class="mb-3">
                                <blockDate ref="closing_time" @updateCalendar="fullTimePickerKey++"/> 
                            </div>
                            <!-- ancien fullTimePicker -- maj le 02 / 06 / 2023
                            <fullTimePicker @selected_time="mobile.date = $event,modaltriger = !modaltriger" :key="fullTimePickerKey" @keyCalendar="keyCalendar" @edit-reservation="editReservation($event)"/> -->
                            <fullTimePicker
                            @selected_time="mobile.date = $event, openAddService($event)"
                            @closing_time="openClosingTime($event)"
                            @reservation_info="openEditService($event)"
                            :key="fullTimePickerKey"
                            @keyCalendar="keyCalendar"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/fr';
import timePickerMeb from '../../../components/time-picker-meb.vue'
import blockDate from '../../../components/functions/block-date.vue'
// import agendaMeb from '../components/agenda-meb.vue';

import {mapState} from 'vuex'
import axios from 'axios';
import addService from '../../../components/functions/add-service.vue';
import pageLoader from '../../../components/pageLoader.vue';
// import addServicePlus from '../../components/functions/add-service-plus.vue';
import fullTimePicker from '../../../components/functions/full-time-picker-meb.vue';
import editDuration from '../../../components/functions/edit-duration.vue';
import { dateMixin } from '../mixins/date';

export default {
    name: 'reservation',
    components : {
    DatePicker,
    timePickerMeb,
    // agendaMeb,
    addService,
    pageLoader,
    // addServicePlus,
    fullTimePicker,
    editDuration,
    blockDate
},
    mixins:[dateMixin],
    data () {
        return {
            // services:null,
            loadershow:false,
            modaltriger:false,
            deliveryinfos:{},
            // infos clients
            name:"",
            phone:'',
            info:'',
            myDate:[],
            skey:1,
            cart: [
                ],
            search:'',
            services:[],
            unavailableschedule: [],
            schedule:[],
            mobile:{
                date:'',
                start:'',
                end:''
            },
            fullTimePickerKey:100,
            // edition reservation
            client_name:"",
            purchases_array:[],
            ulid_reservation_to_edit:"",
            companies:[],
            selected_companies:"",
            purchases:[]
        }
    },
    computed: {
        ...mapState({
            token: state=> state.authentification.token
        }),
        totalpice(){
            let total = 0
            this.services.forEach(element => {
                total = total + element.price
            });
            return total
        },
        isvalid(){
            var check;
            if (this.services.length > 0) {
                check = true
            }else{
                 check = false
            }
            return check
        },
        totalDuration(){
            let duration = 0
            for ( let item of this.services) {
                duration = duration + item.duration
                console.log({duration});
            }
            return this.calculTimes(duration)
        },
        selected_date(){
            return new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
        }
    },
    methods: {
        disabledDate (date) {
            let today = this.datePlusDay(-1)
            return date.getDay()===0 || date.getDay()===1 || date < today
        },
        datePlusDay(nbrofday,date){
            if (!date) {
                date = new Date();
            }
            date =date.setDate(date.getDate() + nbrofday)
            date =new Date(date)
            return date
        },
        async postMyCart (put, ulid) {
            if(!this.phone){
                this.phone = "non mentionné"
            }
            if(!this.email){
                this.email = "non mentionné"
            }
            if(!this.info){
                this.info = "pas de description"
            }
            // const bool = await this.getschedule()
            this.loadershow = true
            // if (bool) {
            //     let text = ""
            //     this.unavailableschedule.forEach(element => {
            //         text+=" "+element.startDate+" "+element.endDate
            //     });
            //     this.loadershow =false
            //     this.$swal({
            //         title: 'Une personne a réservé cette date avant vous:'+text+', merci de bien vouloir sélectionner une autre date',
            //         showCancelButton: true,
            //         confirmButtonColor: '#0b0b0b',
            //         cancelButtonColor: '#fff',
            //         confirmButtonText:'Actualiser'
            //     })
            //     .then(() => console.log("reload")
            //     )
            //     return
            // }

            let body = {
                    on_behalf_of:{
                        name:this.name,
                        phone:this.phone,
                        email:this.email
                    },
                    descriptions:this.info,
                    address: {
                        "name": "Mon échappée belle Gaillac",
                        "street1": "64 Av. Saint-Exupery",
                        "city": "Gaillac",
                        "state": "FR",
                        "zip": "81 600",
                        "country": "FR",
                        "phone": "06 75 43 75 22",
                        "email": "contact@monechappeebelle.fr"
                    },
                    volume:0,
                    weight:0,
                    status:"Sur place",
                    datedelivery:this.dateString(),
                    user:localStorage.getItem(`user_ulid`),
                    staff:localStorage.getItem(`user_ulid`),
                    reservation_date:this.dateString()
            }
            if(put){
                await axios.delete(`reservations/${ulid}`)
                .then(resDeleteOrders =>console.log({resDeleteOrders}))
                .catch(errDeleteOrders=>console.log({errDeleteOrders}))
            }
            axios.post('reservations',body, { withCredentials:true })
                .then( resmyorder => {
                    console.log({resmyorder}); 
                    this.mypurchases(resmyorder.data.ulid)
                    this.services = []
                })
                .catch(errmyorder => {
                    alert('une erreur est survenue dans le post reservation')
                    errmyorder
                })
            
        },
        async mypurchases(reservation){
            //get order ulid to the post my order response
            //post mypurchases
            let request = []
            this.cart.forEach(element => {
                   request.push(
                       axios.post('appointments', this.purchasesBody(element,reservation)))  
            });
            let isdone = null
            isdone = await Promise.all(request).then().catch()
            if(isdone){
            this.services = [] 
            this.loadershow = false
            // this.$router.go()
            }
           
        },
        purchasesBody(purchases,reservation){
            //Dynamic body for mypurchases
            let body = {
                    reservations:reservation,
                    service:purchases.service,
                    // price:purchases.price,
                    // quantity:1,
                    start_hour:this.dateString(purchases.startDate),
                    end_hour:this.dateString(purchases.endDate)
                }
            return body
        },
        async getschedule(){
            this.loadershow =true
            this.message ="Vérification des dates"

            // vérification à partir du purchases avec la restriction de l'ulid sélectionné
             axios.get('appointments',{headers:{"X-AUTH-TOKEN": localStorage.getItem('token')}})
            .then(
                    respurchases => {
                    let reservations = respurchases.data.result.filter(ser => ser.product == null && ser.voucher == null);
                    
                    this.purchases = reservations.filter(reservation =>!this.purchases_array.includes(reservation.ulid))


                    let arr= []
                    this.purchases.forEach(element => {
                        arr.push({
                            datestart:element.start_hour,
                            dateend:element.end_hour
                        })
                    });
                    this.schedule= arr
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                    alert('une erreur est survenue dans le get appointments')
                }
            )

            if(this.schedule){ 
                this.cart.forEach(cartobject => {
                    console.log("cartobject", cartobject);
                    this.schedule.forEach(scheduleobject => {
                        console.log("schedule", scheduleobject);
                        if (
                            // schedule contenu dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) >= this.dateToNumber(scheduleobject.datestart)  &&  
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.dateend)  ||
                            // endDate entre le schedule
                            this.dateToNumber(this.dateString(cartobject.endDate))  >= this.dateToNumber(scheduleobject.datestart)  &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) <= this.dateToNumber(scheduleobject.dateend) ||
                            // schedule englobé dans cart
                            this.dateToNumber(this.dateString(cartobject.startDate)) <= this.dateToNumber(scheduleobject.datestart) &&
                            this.dateToNumber(this.dateString(cartobject.endDate)) >= this.dateToNumber(scheduleobject.dateend)
                            ) 
                        {
                            this.unavailableschedule.push(cartobject)
                        }
                    });
                });
            }
            this.message="Traitement des données en cours"
            if (this.unavailableschedule.length > 0 ) {
                return true
            }
            if(this.unavailableschedule.length == 0){
                return false
            }
        },
        dateToNumber(stringDate){
            let date = stringDate.substr(0,16)
            date = date.replace('-','')
            date = date.replace('T','')
            date = date.replace(':', '')
            date = date.replace('-','')
            date = date.replace(' ','')
            date = Number(date)
            return date
        },
        // changement "-" en "/"
         formatDateStringToStandar(date){
            let y = date.substr(0,4)
            let m = date.substr(5,2)
            let d = date.substr(8,2)
            return m+"/"+d+"/"+y
        },
        
        changeTime(item,payload){
            console.log('item dans change time', item);
            console.log('payload dans change time',payload);
            //dynamic date change format to h:m string version
            if (item.endDate) {
                this.myDate = this.myDate.filter(
                    fil => fil.dateend != this.dateString(item.endDate)
                    
                )
            }
            item.endDate = payload.ed
            item.startDate = payload.sd
            this.myDate.push({
                "dateend": this.dateString(payload.ed),
                "datestart":this.dateString(payload.sd)
            })
        },
        // select heure début mobile
        saveTimeMobile(payload){
            let date = payload.sd //format: Tue Mar 22 2022 09:30:00 GMT+0300 (heure normale d’Afrique de l’Est)
            this.mobile.start = date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes()
        },

         // créer le cart automatiquement -- version
        async createCartMobile(put,ulidreservation){
            this.mobileScreen = true
            this.cart=[]
            let startDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let dateNow = new Date()
            dateNow

            // vérification si inférieure à date aujourd'hui
            // if(startDate<dateNow){
            //     alert('La date et que vous avez sélectionné est déjà passé')
            //     return
            // }

            let endDate = new Date (this.formatDateStringToStandar(this.mobile.date)+' '+ this.mobile.start)
            let price = 0
            let ulid =''
            for (let item of this.services) {
                ulid = item.ulid
                price = item.price
                startDate = endDate
                endDate = this.datePlusDuration(startDate, this.floatTimeToHHMM(item.duration))
                await this.cart.push({
                    service: ulid,
                    startDate: startDate,
                    endDate: endDate,
                    price:price
                })
            }
            this.checkOrder(put, ulidreservation)
        },
        async checkOrder(put, ulid){
                // vérification si prestation vide
                if (!this.isvalid){
                    alert('Choisissez au moins une prestation et veuillez mettre un horaire')
                    return
                }
                // Vérification s'il y a une réservation faite en parallèle avec les mêmes dates
                // const bool = await this.getschedule()
                // if (bool) {
                //     let text = ""
                //     this.unavailableschedule.forEach(element => {
                //         text+=" "+this.getDate(element.startDate)+" au "+ this.getDate(element.endDate)
                //     });
                //     this.loadershow =false
                //     this.$swal({
                //         text: 'Votre réservation se superpose avec une date déjà existante:'+text+', merci de bien vouloir sélectionner une autre date',
                //         showCancelButton: true,
                //         confirmButtonColor: '#0b0b0b',
                //         confirmButtonText:'Actualiser'
                //     })
                //     .then(() => console.log("reload")
                //     )
                //     return
                // }
                // message de retour sur la réservation
                let text2=""
                this.services.forEach(element => {
                    text2+='<div class="d-flex justify-content-between"> <p class="text-left">'+ element.name +'</p> <h3 class="text-right">'+ element.price +'€</h3></div>'
                });
                let textDate = this.cart[0].startDate
            
            let token = localStorage.getItem('refresh_token')
            //  if(this.token){
             if(token){
                 this.loadershow = true
                 axios.get('myinfos')
                .then(() => {
                    this.loadershow = false
                    this.$swal({
                        title: 'Voulez-vous confirmer cette réservation?',
                        html: text2+'<br> <hr class="mt-2"><p class="mt-2 text-left">Heure de début: '+this.getDate(textDate)+'</p>',
                        showCancelButton: true,
                        confirmButtonColor: '#1bcfb4;',
                        cancelButtonColor: 'rgb(206, 118, 122)',
                        confirmButtonText: 'Valider',
                        cancelButtonText: 'Annuler'
                    })
                    .then((result) => {
                        if (result.value) {
                        this.loadershow = true;
                        this.postMyCart(put, ulid)
                        }
                    })
                })
                .catch(errGet => {
                    this.loadershow = false
                    console.log({errGet})
                })
            }else{
                console.log("token non trouvé")
            }
        },
        datePlusDuration(date,duration){
            let date2 = new Date(date)
            let time = this.GetTimeInDate(date2)
                let hours = Number(duration.substr(0,2)) + Number(time.substr(0,2))
                date2.setHours(hours)
                let minutes = Number(time.substr(3,2)) + Number(duration.substr(3,2)) 
                date2.setMinutes(minutes)
                return date2
        },
        GetTimeInDate(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes())  
            return stringdate
        },
        floatTimeToHHMM(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            let heure = Number(timeString.substr(0,2))
            let min = Number(timeString.substr(3,5))
            heure = heure < 10 ? '0' + heure : heure
            min = min < 10 ? '0'+min : min
            let stringHeure = heure+':'+ min
            return stringHeure
        },
        // sort(){
        //     this.services.sort((a,b) => {
        //         if(a.startDate.getTime() > b.startDate.getTime()) return 1;
        //         if(a.startDate.getTime() < b.startDate.getTime()) return -1;
        //         return 0;
        //     });
        // },
        // ouvrir le 'add-service' directement à partir d'une méthode
        openAddService(payload) {
            // réinitialiser les services dans le cart
            this.services= []
            // Appeler la méthode OpenModalWithParameter de add_service avec le paramètre "payload"
            this.$refs.add_service.openModalWithParameter(payload);
            
        },
        // ouvrir le 'add-service' pour l'édition de réservation
        openEditService(event){
            // réinitialiser les services dans le cart
            this.services= []
            // event: {date, ulid}
            this.$refs.add_service.openModalWithParameter(event.date, event.ulid);
        },
        // ouvrir le modal 'block_date' directement à partir d'une méthode
        openClosingTime(payload) {
            this.$refs.closing_time.openModalWithParameter(payload);
        },
        addService(payload, put, ulid){
            console.log('add service dans reservation', payload); 
            for (let service of payload.services) {
                this.services.push({
                    ulid: service.ulid,
                    name: service.name,
                    duration: service.duration,
                    price: service.price,
                    // données  secondaires
                    quantity: 1,
                    datepurchase: this.dateString(),
                    endDate: "",
                    startDate: ""
                })
            }
            // assignation des infos clients et date
            this.name=payload.client.name
            this.phone=payload.client.phone
            this.info= payload.client.info
            this.mobile.date = payload.date.slice(0, 10)
            this.mobile.start = payload.date.slice(11, 16)
            this.createCartMobile(put, ulid)
        },
        // plusaddservice(data){
        //     let service = data.service
        //     //repopulate cart 
        //    this.services.push({
        //         ulid: service.ulid,
        //             name: service.name,
        //             duration: this.calculTimes(service.duration),
        //             price: service.price,
        //             // données  secondaires
        //             quantity: 1,
        //             datepurchase: data.datepurchase,
        //             endDate: data.ed,
        //             startDate: data.sd
        //     })
        //     this.myDate.push({
        //         "dateend": this.dateString(data.ed),
        //         "datestart":this.dateString(data.sd)
        //     })
        // },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        removeService(service){
            this.$swal({
            title: 'Suppression',
            text: 'Voulez-vous supprimer cette prestation?',
            showCancelButton: true,
            confirmButtonColor: '#0b0b0b',
            cancelButtonColor: 'rgb(172, 169, 169)',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
            })
            .then((result) => {
            if (result.value) {
            //remove service in cart
            this.services.splice(this.services.indexOf(service), 1);
                this.$swal(
                {
                title:'Supprimé!',
                text:'La prestation a bien été supprimée',
                icon:'success',
                confirmButtonColor: '#0b0b0b',
                confirmButtonText: 'Fermer'
                }
                )          
            }
        })
        },
        checkDate(item){
            let ed = true
            let cart = this.services.filter( el  => item.datepurchase  == el.datepurchase)
            cart.forEach(element => {
                if (element.endDate == "") {
                    ed = false
                }
            })
            return cart[cart.length - 1] == item && ed
        },
        blockdate(){
            this.$router.push({
                name:"blockdate"
            })
        },
        keyCalendar(payload){
            this.fullTimePickerKey=payload
        },
        editDuration(duration,index){
            this.services[index].duration = Number(this.TimeToFloat(duration))
        },
        // EDITION RESERVATION
        getOrdersDetails(ulid, date, start){
            axios.get(`reservations/${ulid}`)
            .then(resGetOrdersDetails => {
                this.mobile.date = new Date(date).toISOString()
                this.mobile.start = start
                // this.client_name = "de " + orders.deliveryinfos.name
                let purchases=resGetOrdersDetails.data.result.appointments
                for (let purchase of purchases) {
                    this.purchases_array.push(purchase.ulid)
                    this.services.push({
                            ulid: purchase.service.ulid,
                            name: purchase.service.name,
                            duration: purchase.service.duration,
                            price: purchase.service.price,
                            // données  secondaires
                            quantity: 1,
                            datepurchase: this.dateString(),
                            endDate: "",
                            startDate: ""
                    })
                }
                this.createCartMobile()
                this.loadershow =false
            })
            .catch(errOrdersDetails => {
                console.error(errOrdersDetails);
                if(errOrdersDetails.response.status == 404){
                    this.notFound = true
                    this.loadershow =false
                    return
                }
            })
        },
        getCompanies(ulid){
            axios.get(`companies/${ulid}`)
            .then(resGetCompanies => {
                this.companies = resGetCompanies.data.result
            })
            .catch(errGetCompanies => errGetCompanies)
        },
        async editReservation(event){
            this.services=[] //videna aloha sao misy ambina reservation
            console.log("edit reservation dans reservation",event);
            await this.getOrdersDetails(event.ulid,event.date,event.start) 
        }

    },
    created() {
    },
    mounted(){
        if(this.$route.params.ulid){
            this.ulid_reservation_to_edit = this.$route.params.ulid
            let ulid = this.$route.params.ulid
            this.getOrdersDetails(ulid)
        }
    }
}
</script>

<style scoped>
    .cream-background {
        position: relative;
        min-height: 30rem;
        background-image: white;
        background-size: 80%;
        background-position:top -17rem right -4rem;
        background-repeat: no-repeat;
    }
.accordion.accordion-bordered .card .card-header a::before {
    color: #e0d075;
}
/* mettre à la ligne lorsque la table est pleine */
td {
    white-space: break-spaces
}
/* reservation mobile */
.reservation-mobile .card-body{
    /* background: white; */
    box-shadow: 1px 3px 5px gainsboro;
    border-radius: 4px;
}
.construct{
height: 25px;
width: 25px;
border-radius: 50%;
position: absolute;
right: -10px;
top: -7px;
font-size: 17px;
padding-right: 0px;
padding-left: 0.5px;
padding-bottom: 0px;
padding-top: 3px;
}
.primary {
    color: #b66dff
}
@media screen and (max-width: 992px) {
    .btn-dynamic-block{
        width: 100%
    }
}
::v-deep .vuecal__event{
    cursor: pointer;
}
/* animation survol sur les heures */
:deep .cell-time-label:hover{
    transform: scaleX(1.03);
    background: #dfdfdf;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    z-index:3;
    font-size: 1em
}
</style>